<template>
    <div class="user-card">
        <ContentLoader :height="600" secondaryColor="#ccd8ff" primaryColor="#e7ecff">
            <circle cx="200" cy="130" r="120"></circle>
            <rect x="75" y="320" rx="0" ry="0" width="240" height="60"></rect>
            <rect x="45" y="410" rx="0" ry="0" width="300" height="40"></rect>
            <rect x="60" y="540" rx="4" ry="4" width="260" height="140"></rect>
        </ContentLoader>
    </div>
</template>
<script>
import {ContentLoader} from 'vue-content-loader';

export default {
    components: {
        ContentLoader
    }
}
</script>

<!--
<style scoped>
.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */
{
    transform: translateX(10px);
    opacity: 0;
}
</style>-->
